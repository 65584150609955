.navbar_container {
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  background-color: var(--background-color);
  z-index: 100;
  position: sticky;
  top: -1px;
  -webkit-box-shadow: 0px 0px 19px -10px rgba(66, 68, 90, 0);
  -moz-box-shadow: 0px 0px 19px -10px rgba(66, 68, 90, 0);
  box-shadow: 0px 0px 19px -10px rgba(66, 68, 90, 0);
  /* transition: all 0.2s ease-in-out; */
  transition: width 0.3s ease-in, height 0.3s ease-in;
}

.navbar_container[data-stuck] .header_logo > svg {
  width: 100px;
  height: auto;
}

.navbar_container[data-stuck] {
  -webkit-box-shadow: 0px 0px 19px -10px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 19px -10px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 19px -10px rgba(66, 68, 90, 1);
}

.header_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header_logo svg {
  transition: width 0.3s ease-in, height 0.3s ease-in;
}

.header_links_container {
  display: flex;
  align-items: center;
  gap: 30px;
}

.header_link > a, .header_link > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: 600;
}

.header_links_dropdown_div {
  display: flex;
  align-items: center;
  gap: 3px;
}

.header_links_dropdown_div > svg {
  width: 15px;
  height: 15px;
  fill: var(--primary-color);
  margin-top: 3px;
  transform: rotate(-180deg);
  transition: all 0.3s ease-in;
}

.header_link:hover {
  color: var(--secondary-color);
}

.header_link_underline {
  position: relative;
  width: 0%;
  height: 2px;
  background-color: var(--secondary-color);
  transition: all 0.3s ease-in-out;
}

.header_link:hover .header_link_underline {
  width: 100%;
}

.header_link:hover .header_links_dropdown_div > svg {
  transform: rotate(-90deg);
}

.header_link:hover .header_dropdown_container_wrapper {
  max-height: 400px;
}

.header_link:hover .header_dropdown_container {
  -webkit-box-shadow: 0px 0px 19px -10px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 19px -10px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 19px -10px rgba(66, 68, 90, 1);
  /* max-width: 1000px; */
}

.header_link:hover .header_dropdown_link {
  transition-delay: 0.45s;
  transform: translate(0px);
  opacity: 1;
}

.header_link:hover .header_dropdown_image_container {
  transition-delay: 0.6s;
  opacity: 1;
}

.header_link:hover .header_dropdown_blog_container > a {
  transition-delay: 0.45s;
  transform: translate(0px);
  opacity: 1;
}

.header_link:hover .header_dropdown_blog_container > a:nth-child(1) {
  /* transition-delay: 0.45s; */
  /* width: calc(100% - 200px); */
  /* transform: translate(0px);
  opacity: 1; */
}

.header_link:hover .header_dropdown_blog_container > a:nth-child(2) {
  transition-delay: 0.65s;
  /* width: calc(100% - 200px); */
  /* transform: translate(0px); */
  /* opacity: 1; */
}

.header_link:hover .header_dropdown_blog_container > a:nth-child(3) {
  transition-delay: 0.85s;
  /* width: calc(100% - 200px); */
  /* transform: translate(0px); */
  /* opacity: 1; */
}


/* header dropdown container */
.header_dropdown_cursor_path {
  position: absolute;
  width: 100%;
  height: 40px;
}

.header_dropdown_container_wrapper {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  flex-direction: row !important;

  /* flex-direction: column; */
  /* align-items: center; */
  /* width: 100%; */
  z-index: 2000;
  top: 75px;
  margin-left: -10px;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.4s ease-in;
  pointer-events:none;
}

.header_dropdown_container {
  pointer-events: all;
  cursor: default;
  display: flex;

  /* width: calc(80% - 80px); */
  max-width: 800px;
  height: 100%;
  background-color: var(--background-color);
  padding: 20px;
  margin: 10px;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(66, 68, 90, 0);
  -moz-box-shadow: 0px 0px 0px 0px rgba(66, 68, 90, 0);
  box-shadow: 0px 0px 0px 0px rgba(66, 68, 90, 0);

  transition: all 0.4s ease-in;
}

.header_dropdown_link_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 200px;
}

.header_dropdown_link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: 700;
  transform: translate(-20px);
  opacity: 0;
  transition: transform 0.15s ease-in, opacity 0.15s ease-in;
}

.header_dropdown_link:hover {
  color: var(--secondary-color);
}


/* .header_dropdown_container_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: absolute;
  z-index: 2000;
  top: 75px;
  left: 0;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.4s ease-in;
  pointer-events:none;
}

.header_dropdown_container {
  pointer-events: all;
  cursor: default;
  display: flex;
  width: calc(80% - 80px);
  max-width: 800px;
  height: 100%;
  background-color: var(--background-color);
  padding: 30px;
  margin: 10px;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(66, 68, 90, 0);
  -moz-box-shadow: 0px 0px 0px 0px rgba(66, 68, 90, 0);
  box-shadow: 0px 0px 0px 0px rgba(66, 68, 90, 0);

  transition: all 0.4s ease-in;

}

.header_dropdown_link_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 200px;
}

.header_dropdown_link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: 700;
  transform: translate(-20px);
  opacity: 0;
  transition: transform 0.15s ease-in, opacity 0.15s ease-in;
}

.header_dropdown_link:hover {
  color: var(--secondary-color);
}

.header_dropdown_blog_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: calc(100% - 200px);

  height: auto;
  overflow: hidden;
  transition: all 0.3s ease-in;
}

.header_dropdown_blog_container > a {
  height: 100%;
  flex: 1;
  opacity: 0;
  transform: translate(-20px);
  transition: all 0.3s ease-in;
}

.header_dropdown_image_container {
  position: relative;
  width: calc(100% - 200px);
  border-radius: 5px;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s ease-in;
} */


@media only screen and (max-width: 650px) {
  .navbar_container {
    padding: 20px;
  }

  .header_logo svg {
    width: 180px;
  }

  .header_links_container {
    gap: 20px;
  }
}