/* hamburger menu */
.hamburger_container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-around;
  width: 33px;
  height: 33px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 2000;
}

.hamburger_container:focus {
    outline: none;
}

.hamburger_container > div {
  width: 100%;
  height: 2px;
  border-radius: 10px;
  transition: all 0.6s;
  position: relative;
  transform-origin: 1px;
  background-color: var(--primary-color);
}

/* mobile menu */
.mobile_menu {
  position: fixed;
  top: 0px;
  right: 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  overflow: hidden;

  max-width: 430px;
  height: 100vh;
  /* left: 73px; */
  
  background-color: var(--background-color);
  z-index: 1000;
  transition: all 0.6s ease-in-out;

  /* border-left: 2px solid var(--accent-color); */
}

.mobile_menu_link {
  text-decoration: none;
  color: var(--primary-color);
  font-size: 2rem;
  font-weight: 700;
  margin: 1rem;
  margin-right: 40px;
  opacity: 0;

  transition: all 0.4s linear;
  cursor: pointer;
}

.mobile_menu_link_underline {
  position: relative;
  width: 0%;
  height: 2px;
  background-color: var(--secondary-color);
  transition: all 0.3s ease-in-out;
}

.mobile_menu_links_dropdown_div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 3px;
}

.mobile_menu_dropdown_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
  max-height: 0px;
  transition: all 0.4s ease-in;
}

.mobile_menu_dropdown_wrapper > a {
  font-size: 1rem;
  font-weight: 600;
  color: var(--primary-color);
  margin: 0.5rem;
  text-decoration: none;
}

.mobile_menu_links_dropdown_div > svg {
  width: 30px;
  height: 30px;
  fill: var(--primary-color);
  margin-top: 5px;
  margin-right: -6px;
  transform: rotate(-180deg);
  transition: all 0.3s ease-in;
}

.mobile_menu_link:hover .mobile_menu_link_underline {
  width: 100%;
}

.mobile_menu_link:hover .mobile_menu_links_dropdown_div > svg {
  transform: rotate(-90deg);
}

.mobile_menu_link:hover .mobile_menu_dropdown_wrapper {
  max-height: 192px;
}

.mobile_menu_dropdown_wrapper > a:hover  {
  color: var(--secondary-color);
}

/* opacity: 1;
visibility: visible;
transition: opacity .32s cubic-bezier(.4,0,.6,1) 80ms,visibility .32s step-start 80ms; */

.mobile_menu_background {
  cursor: pointer;
  /* background: rgba(232,232,237,.4); */
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  visibility: hidden;
  position: fixed;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: opacity .32s cubic-bezier(.4,0,.6,1) 80ms,visibility .32s step-end 80ms;
}

@media only screen and (max-width: 430px) {
  .mobile_menu {
    justify-content: center;
    align-items: center;
  }

  .mobile_menu_link {
    text-align: center;
    margin-right: 0;
    margin-left: 0;
    font-size: 2.5rem;
  }

  .mobile_menu_links_dropdown_div > svg {
    margin-right: 0;
  }
}