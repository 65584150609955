.footer_container {
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px;
}

.footer_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
}

.footer_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.footer_logo svg {
  width: 200px;
  height: auto;
}

.footer_links_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin-top: 30px;
  margin-left: 30px;
}

.footer_link {
  text-decoration: none;
  color: var(--primary-color);
  font-size: 1.8rem;
  font-weight: 600;
}

.footer_link:hover {
  color: var(--secondary-color);
}

.footer_link_underline {
  width: 0%;
  height: 2px;
  background-color: var(--secondary-color);
  transition: all 0.3s ease-in-out;
}

.footer_link:hover .footer_link_underline {
  width: 100%;
}

.footer_right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  width: 50%;
}

.footer_right hr {
  width: 60%;
  background-color: var(--primary-color);
  margin: 0px;
  margin-top: 20px;
}

.footer_contact_link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--secondary-color);
  font-size: 1.8rem;
  font-weight: 600;
}

.footer_contact_link:hover {
  color: var(--primary-color);
}

.footer_contact_link svg {
  margin-left: 10px;
  margin-top: 5px;
}

.footer_right > p {
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0px;
}

.footer_right > div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer_right > div > svg {
  fill: var(--primary-color);
  font-size: 1.8rem;
}

.footer_right > div > a {
  text-decoration: none;
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: 600;
}

@media only screen and ( max-width: 650px)  { 
  .footer_container {
    flex-direction: column;
    align-items: flex-start;
    margin: 20px;
  }

  .footer_container > div {
    width: 100%;
  }

  .footer_links_container {
    margin-top: 20px;
    margin-left: 15px;
  }

  .footer_right {
    margin-top: 20px;
  }
}