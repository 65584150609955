.navlink_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* gap: 40px; */
}

.navlink_label {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: var(--primary-color);
  font-size: 1.8rem;
  font-weight: 600;
}

.navlink_dropdown_div {
  display: flex;
  align-items: center;
  gap: 3px;
}

.navlink_dropdown_div>svg {
  width: 20px;
  height: 20px;
  fill: var(--primary-color);
  margin-top: 10px;
  transform: rotate(-180deg);
  transition: all 0.3s ease-in;
}

.navlink_container:hover {
  color: var(--secondary-color);
}

.navlink_underline {
  position: relative;
  width: 0%;
  height: 2px;
  background-color: var(--secondary-color);
  transition: all 0.3s ease-in-out;
}

.navlink_container:hover .navlink_underline {
  width: 100%;
}

.navlink_container:hover .navlink_dropdown_div>svg {
  transform: rotate(-90deg);
}

.navlink_dropdown_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  gap: 10px;
  margin-left: 10px;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.navlink_dropdown_wrapper>a:first-child {
  margin-top: 10px;
}

.navlink_dropdown_wrapper>a {
  text-decoration: none;
  color: var(--primary-color);
  font-size: 1rem;
  /* font-weight: 600; */
}

.navlink_dropdown_wrapper>a:hover {
  color: var(--secondary-color);
}

.navlink_container:hover .navlink_dropdown_wrapper {
  max-height: 200px;
}