/* :root {
  --background-color: #fff;
 
} */

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  background-color: var(--background-color);
  font-family: "acumin-pro", sans-serif;
  margin: 0px;
  /* margin-top: 80px; */
}

button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

:root, html[data-theme='light'] {
  --background-color: #ffffff;
  --primary-color: #24231F;
  --secondary-color: #36ABD6;
  --accent-color: #2D3D42;
  --background-gray: #F5F5F5;
  --opacity-color: #24231f70;
  --dark-opacity-color: #24231f70;

}

html[data-theme='dark'] {
  --background-color: #24231F;
  /* --primary-color: #FBFBF2; */
  --primary-color: #FDFDFF;
  --secondary-color: #36ABD6;
  --accent-color: #2D3D42;
  --background-gray: #7b7b7b;
  --opacity-color: #313131;
  --dark-opacity-color: #FDFDFF70;
}


@media only screen and ( max-width: 768px)  { 
  html {
    font-size: 14px;
  }
}

/* @media only screen and ( max-width: 425px) { 
  html {
    font-size: 12px;
  }
} */



@media (prefers-reduced-motion: reduce) {
  * {
    cursor: auto; /*reverts to the default cursor */
  }
}
/* 
.small{
  width: 10px;
  height: 10px;
  border: 2px solid red;
  box-shadow: 0 0 0 10px rgba(0, 0, 255, 0.2);
  border-radius: 50%;
  z-index: 1000;
} */