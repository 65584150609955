.toggle_container {
  position: relative;
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  cursor: pointer;
  background-color: var(--background-color);
  border: 2px solid var(--primary-color);
  width: 50px;
  height: 25px;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
  padding: 2px;
}

.toggle_container:hover > div > svg {
  /* fill: var(--accent-color) !important; */
}

.toggle_container:hover {
  background-color: var(--accent-color);
}

.toggle_icon {
  position: absolute;
  right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: var(--primary-color);
  transition: all 0.1s ease-in-out;
}

.toggle_icon svg {
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--background-color);
  font-size: 14px;
  transition: all 0.2s ease-in-out;
}